<template>
    <v-card :style="{ borderRadius: '15px' }"
            max="700"
            min-height="620"
            class="my-1"
            width="500">

        <v-card-text class="pa-6">
            <v-row class="mb-2">

                <v-col cols="2" align-self-center>
                    <v-icon size="4.5em" :color="!enabled ? 'error' : null">mdi-account-circle</v-icon>
                </v-col>

                <v-col cols="8" align-self-center>
                    <div class="text-h5 font-weight-bold">
                        <span>{{ gender === 'male' ? `${$t('Mr.')} ` : gender === 'female' ? `${$t('Mrs.')} ` : '' }}{{ name ? `${name} ` : '' }}{{ surname }}</span>
                    </div>
                    <div>
                        <span class="title">{{ $t(role) }}</span>
                    </div>
                </v-col>

                <v-col v-if="editable" cols="2" align-self-center>
                    <v-btn icon
                        @click="$emit('edit-item')">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </v-col>

            </v-row>

            <div v-for="(row, idx) in setRows"
                         :key="idx">
                <v-list-item-title :class="['subtitle-1', row.name === null ? 'mb-3' : 'mb-1']">
                    <v-row>
                        <v-col cols="3" align-self-center>
                            <div>
                                {{ row.name }}
                            </div>
                        </v-col>

                        <v-col cols="9"  align-self-center>
                            <div v-for="val, vidx in row.value.slice(0, 2)"
                                :key="`row-val-${vidx}`">

                                <v-tooltip bottom>
                                    <template v-slot:activator="{}">
                                        <div v-if="row.type === 'text'">
                                            <v-textarea solo flat readonly :value="val.name">
                                            </v-textarea>
                                        </div>
                                        <v-list-item-title v-else class="text-h6" :title="val.name">
                                            <v-btn v-if="row.copy" icon small @click="copyContent(val.name)">
                                                <v-list-item-icon class="mr-0">
                                                    <v-icon size="small">mdi-content-copy</v-icon>
                                                </v-list-item-icon>
                                            </v-btn>
                                            <a v-if="row.link"
                                                :href="row.link(val.id)">
                                                {{ val.name }}
                                            </a>
                                            <span v-else>{{ val.name }}</span>
                                        </v-list-item-title>
                                    </template>

                                    <span>{{ val.name }}</span>
                                </v-tooltip>
                            </div>
                        </v-col>

                    </v-row>
                </v-list-item-title>
            </div>

            <v-container grid-list-md text-right pa-0>
                {{ $t('created') }}: {{ dateFormat(createdAt) }}<br/>
                {{ $t('modified') }}: {{ dateFormat(updatedAt) }}
            </v-container>
        </v-card-text>
    </v-card>
</template>


<script>
import { mapActions } from 'vuex'

const moment = require('moment-timezone')

export default {
    props: {
        name: String,
        id: Number,
        surname: String,
        company: String,
        role: String,
        phones: Array,
        mails: Array,
        projects: Array,
        timezone: String,
        note: String,
        gender: String,
        data: Object,
        enabled: Boolean,
        languages: String,
        editable: Boolean,
        createdAt: String,
        updatedAt: String,
    },

    data() {
        return {
            item: this.data,
        }
    },

    computed: {
        setRows() {
            return [
                { name: this.$t('company'), value: [{ name: this.company }] },
                { name: null, value: [] },
                { name: this.$t('phone'), value: this.phones, copy: true },
                { name: this.$t('mails'), value: this.mails, copy: true },
                { name: null, value: [] },
                { name: this.$t('projects'), value: this.projects, link: id => `/project/${id}` },
                // { name: this.$t('history'), value: this.projects, link: id => `/project/${id}` },
                { name: null, value: [] },
                { name: this.$t('Languages'), value: [{ name: this.languages }] },
                { name: null, value: [] },
                { name: this.$t('note'), value: [{ name: this.note }], type: 'text' },
            ]
        },
    },

    methods: {
        ...mapActions({
            showSnackbar: 'page/showSnackbar',
            setSnackbar: 'page/setSnackbar',
        }),

        copyContent(copyData) {
            navigator.clipboard.writeText(copyData)

            this.setSnackbar({
                type: 'success',
                text: `${this.$t('Content copied!')}`,
            })
            this.showSnackbar(true)
        },

        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD')
        },
    },

    created() {

    },
}
</script>
